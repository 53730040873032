<template>
  <div class="page_bg">
    <van-skeleton title avatar :row="23" :loading="loading">
      <!-- 返回和购物车按钮 -->
      <div class="class_del_banck_cart flex-r-sb-c" style="z-index:10001">
        <van-image width="0.8rem" height="0.8rem"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/public/top_back_round.png" fit="fill"
                   @click="backPath"
        />
      </div>
      <!-- 视频播放 -->
      <div class="class_del_video" style="height: 7.49rem;">
        <tx-video v-if="showVideo" ref="txVideo" :options="videoOptions" />
        <!-- 课程未购买 -->
        <div v-if="!proDetailInfo.isBuy" class="class_del_vedio_nopay flex-c-c-c">
          <div class="class_del_vedio_tips">课程尚未购买，快快购买学习吧！</div>
          <div class="class_del_vedio_btn">
            <van-button round block type="primary" color="#0767ab" @click="subClass()">立即开课</van-button>
          </div>
        </div>
        <!-- 课程已经冻结 -->
        <div v-else-if="proDetailInfo.isLock" class="class_del_vedio_frozen  flex-c-c-c">
          <div class="class_del_vedio_tips">课程处于冻结状态，请联系客服咨询~</div>
          <div class="class_del_vedio_btn">
            <van-button round block type="primary" color="#0767ab" @click="openKeFu">联系客服</van-button>
          </div>
        </div>
      </div>
      <!-- 产品主要信息 -->
      <div class="study_del_info">
        <div class="info_price_salenNum flex-r-sb-c">
          <div class="info_price_new">￥<span style="font-size: 0.52rem;">{{ proDetailInfo.salePrice }}</span></div>
          <div class="info_salenNum">{{ proDetailInfo.playNum }} 次播放</div>
        </div>
        <div class="info_name van-multi-ellipsis--l2">{{ proDetailInfo.name }}</div>
        <div class="info_card">
          <div class="card_teacth">讲师：{{ proDetailInfo.teacher }}</div>
          <div class="card_dec">{{ proDetailInfo.description }}</div>
        </div>
      </div>
      <!-- 课程目录 -->
      <div class="study_del_info2 flex-c-s-s">
        <div class="info2_item flex-r-sb-c">
          <div class="info2_item_label">课程列表</div>
          <div v-if="proDetailInfo.resourceList" class="info2_item_value" @click="openshow">
            全部{{ proDetailInfo.resourceList.length }}节
            <van-icon name="arrow" />
          </div>
        </div>
        <div class="info2_lists flex-r-s-s">
          <div v-for="(list_item,list_index) in proDetailInfo.resourceList" :key="list_index" class="info2_list "
               @click="changeProject(list_item)"
          >
            <van-image width="3.47rem" height="2.19rem" :src="list_item.imageUrl" fit="fill" />
            <div class="info2_item_value van-multi-ellipsis--l2">{{ list_item.name }}</div>
          </div>
        </div>
      </div>
      <!-- 产品相关详情介绍图 -->
      <div class="pro_del_imgs">
        <!-- 产品介绍 -->
        <van-image v-for="(img01,index_img01) in proDetailInfo.imageUrl" :key="index_img01" width="10rem" :src="img01"
                   fit="fill"
        />
      </div>
      <br>
      <br>
    </van-skeleton>
    <!-- 底部提交bar -->
    <studyDel ref="study_bar" :info="proDetailInfo" @playvideo="playvideo" />
    <!-- 课程列表 -->
    <van-popup v-model="show" round position="bottom" :style="{ height: '60%' }">
      <div class="study_del_info2 flex-c-s-s">
        <div class="info2_item flex-r-sb-c">
          <div class="info2_item_label" style="font-siez:0.48rem">课程目录</div>
          <div class="info2_item_value" @click="show = false">
            <van-icon name="clear" size="0.53rem" />
          </div>
        </div>
        <div class="study_list_pupoer">
          <classCard03 v-for="(pro_item,pro_index) in proDetailInfo.resourceList" :key="pro_index" :info="pro_item"
                       @changeP="changeProject"
          />
        </div>
      </div>
    </van-popup>
    <!-- 客服组件 -->
    <kefuBtn ref="mykefuBtn" />
  </div>
</template>

<script>
import './index.scss'
import { cloudroom_info, cloudroom_saveOrder } from '@/api/study'
import studyDel from '@/components/bar/study_del'
import txVideo from '@/components/cs-txVideo/cs-txVideo'
import classCard03 from '@/components/class_card_03'
import kefuBtn from '@/components/kefu_btn'
export default {
  name: 'ProDetail',
  components: { studyDel, txVideo, classCard03, kefuBtn },
  data() {
    return {
      loading: true,
      pid: 0,
      companyId: '1000',
      proDetailInfo: {},
      videoOptions: { file_id: '', appId: '1304134186' },
      show: false,
      showVideo: false
    }
  },
  created() {
    this.$utils.cartNum(this.$store)
    this.pid = this.$route.query.id
    this.getDetail()
  },
  methods: {
    backPath() { window.history.back() },
    getDetail() {
      cloudroom_info({ cloudRoomCourseId: this.pid }).then(res => {
        this.showVideo = true
        this.proDetailInfo = res.data
        this.videoOptions.file_id = res.data.resourceList[0].fileId
        this.loading = false
      })
    },
    openshow() {
      this.show = true
    },
    openKeFu() { this.$refs.mykefuBtn.changeShow(true) },
    // 提交class
    subClass() {
      const parms = { cloudRoomCourseId: this.proDetailInfo.cloudRoomCourseId, phone: this.proDetailInfo.mobile, expireType: this.proDetailInfo.exprieType }
      cloudroom_saveOrder(parms).then(res => {
        this.$router.push('/studyClass/studyOrder?id=' + res.data.orderId + '&type=1' + '&playNum=' + this.proDetailInfo.playNum)
      })
    },
    // 切换课程
    changeProject(item) {
      this.videoOptions.file_id = item.fileId
      this.show = false
    },
    // 开启视频
    playvideo() { this.$refs.txVideo.play() }
  }
}
</script>

<style>
</style>
